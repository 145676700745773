const rules = {
  required: [(v) => !!v || "required"],
  vinRules: [
    (v) => !!v || "VIN is required",
    (v) => (v && v.length <= 50) || "VIN must be less than 50 characters",
  ],
  tuSerialRules: [
    (v) => !!v || "TU Serial is required",
    (v) => (v && v.length <= 10) || "TU Serial must be less than 10 characters",
  ],
  modelRules: [
    (v) => !!v || "Model is required",
    (v) => (v && v.length <= 100) || "Model must be less than 100 characters",
  ],
  refRules: [
    (v) => !!v || "Ref/PO no is required",
    (v) => (v && v.length <= 50) || "Ref/PO no must be less than 50 characters",
  ],
  notesRules: [
    (v) => !!v || "Notes is required",
    (v) => (v && v.length <= 750) || "Notes must be less than 750 characters",
  ],
  engineHoursRules: [
    (v) => !!v || "Engine Hours is required",
    (v) => (v && v.length <= 12) || "Engine Hours must be less than 12 characters",
  ],
  assetNameRules: [
    (v) => !!v || "Asset Name is required",
    (v) => (v && v.length <= 100) || "Asset Name must be less than 100 characters",
  ],
  yearRules: [
    (v) => !!v || "Year is required",
    v => /^(?:[0-9]{4,4}|)$/.test(v) || "Please enter valid year.",
  ],
  nameRules: [
    (v) => !!v || "Name is required",
    (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
  ],
  emailRules: [
    (v) => !!v || "E-mail is required",
    (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
  ],
  passwordRules: [
    (v) => !!v || "Password is required",
    (v) => (v && v.length >= 5) || "Password must have 5+ characters",
    (v) => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
    (v) => /(?=.*\d)/.test(v) || "Must have one number",
    (v) => /([!@$%])/.test(v) || "Must have one special character [!@#$%]",
  ],
  HrsRules: [
    (v) => !!v || "Value is required",
    (v) => (v >= 0) || 'Must be greater than 0',
    (v) => (v <= 23) || 'Must be less than 24'
  ],
  MinRules: [
    (v) => !!v || "Value is required",
    (v) => (v >= 0) || 'Must be greater than 0',
    (v) => (v <= 59) || 'Must be less than 59'],
  estimationHoursRules: [
    (v) => (v >= 0) || 'Must be greater than 0',
  ],
  amountRules: [
    (v) => !!v || "Value is required",
    (v) => (v >= 0) || 'Must be greater than 0',
  ],
  npspls: [
    (v) => !!v || "required",
    v => /^[^*|":<>[\]{}`\\()';@&$]+$/.test(v) || "No special characters are allowed"
  ],
  phoneNumberRequired: [
    (v) => !!v || "Required",
    v =>
    /^(?:[0-9]{9,12}|)$/.test(v) ||
    "Please enter valid mobile number. Just the 9-12 numbers!",
  ],
  phoneNumber: v =>
  /^(?:[0-9]{9,12}|)$/.test(v) ||
  "Please enter valid mobile number. Just the 9-12 numbers!",
};
export default rules;
