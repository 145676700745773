// import apiService from "../../../api/api.service";
import userService from "../../../api/user.service";
import rules from "../../../utils/rules";
import ImageInput from "@/views/ImageInput";
import pageheader from "../../common/pageheader";
import { mapGetters } from "vuex";
import utils from "../../../utils/utils";
export default {
  name: "profile",
  components: {
    ImageInput: ImageInput,
    pageheader
  },
  props: [],
  data() {
    return {
      input: {},
      valid: true,
      loading: false,
      result: [],
      showAvatarPicker: false,
      isEditing: false,
      rules: rules,
      avatar: {
        imageURL: null
      },
      saving: false,
      saved: false,
      isUpload: false,
      isAvatar: false,
      isProfile: false,
      roleChange: "",
      id: "profile"
    };
  },
  watch: {
    avatar: {
      handler: function (val) {
        if (val.isUpload) {
          this.isAvatar = true;
        }
        this.saved = false;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["roles"])
  },
  mounted() {
    this.userId = this.$store.getters.userId;
    if (this.id == "profile") {
      // getting input content
      this.input = utils.getInputContent("profile", this.roles);
    }
    this.getProfileById();
  },
  methods: {
    async getProfileById() {
      this.isProfile = true;
      try {
        let temp = await userService.getProfile(this.userId);
        this.result = temp.data;
        // if (this.result.userRole == "Customer") {
        //   this.roleChange = "External Customer";
        // } else if (this.result.userRole == "Support Admin") {
        //   this.roleChange = "ProServ Portal Manager";
        // } else if (this.result.userRole == "Support Manager") {
        //   this.roleChange = "ProServ Portal Support Manager";
        // } else if (this.result.userRole == "Contractor") {
        //   this.roleChange = "Contractor";
        // } else {
        //   this.roleChange = this.result.userRole;
        // }
        if (this.result.image) {
          this.avatar.imageURL = this.result.image;
        }
        this.isProfile = false;
      } catch (err) {
        this.isProfile = false;
        this.$toast.error("Unable to load the report requests" + err);
      }
    },
    cancelUpdate(isedit) {
      this.isEditing = !isedit;
    },
    async uploadImage() {
      this.saving = true;
      this.isUpload = true;
      try {
        let payload = {
          profileImage: this.avatar.imageString
        };
        let response = await userService.updateProfileImage(
          this.userId,
          payload
        );
        if (response.isSuccess) {
          this.$toast.success("Profile image updated successfully!");
        }
      } catch (err) {
        this.$toast.error("Unable to upload profile image" + err);
      }
      setTimeout(() => this.savedAvatar(), 1000);
    },
    savedAvatar() {
      this.isAvatar = false;
      this.saving = false;
      this.saved = true;
    },
    cancelUpload() {
      // // if (this.result.image) {
      // //   this.avatar.imageURL = this.result.image;
      // // }
      // this.avatar.imageURL = null;
      // setTimeout(() => this.savedAvatar(), 5);
      if (this.result.image) {
        this.avatar.imageURL = this.result.image;
      }
      else{
      this.avatar.imageURL = null;
      }
      setTimeout(() => this.savedAvatar(), 5);
    },
    async profileUpdate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let payload = {
          firstName: this.result.firstName,
          lastName: this.result.lastName,
          mobileNumber: this.result.mobileNumber,
          officePhone: this.result.officePhone
        };
        try {
          let apiResponse = await userService.updateProfile(
            this.userId,
            payload
          );
          if (apiResponse) {
            this.loading = false;
            this.$toast.success("Updated details successfully!");
            this.cancelUpdate(true);
          }
        } catch (err) {
          this.loading = false;
          this.$toast.error("Oops!!! something went wrong" + err);
          this.getProfileById();
        }
      }
    }
  }
};
